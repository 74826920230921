import React, { useState, useRef } from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
`

const Inner = styled.div`
  max-width: 1500px;

  @media (min-width: 1200px) {
    min-height: 659px;
  }
`

const IconWrapper = styled.div`
  left: -60px;
  bottom: 50px;
`

const Icon = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 425px;
    height: 424px;
  }

  @media (max-width: 1399.98px) {
    width: 325px;
    height: 324px;
  }

  @media (max-width: 991.98px) {
    width: 225px;
    height: 224px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    padding-top: 12rem;
    margin-bottom: 2rem;
    -webkit-text-stroke: 2px ${({ theme }) => theme.color.light};
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.bold};

    @media (min-width: 992px) {
      font-size: 200px;
      line-height: 200px;
    }

    @media (max-width: 991.98px) {
      font-size: 100px;
      line-height: 100px;
      padding-top: 8rem;
      padding-bottom: 3rem;
    }

    @media (max-width: 575.98px) {
      font-size: 60px;
      line-height: 60px;
    }
  }
`

const UspIcon = styled(Plaatjie)`
  background-color: ${({ theme }) => theme.color.light};
  width: 96px;
  height: 96px;
  aspect-ratio: 1/1;

  @media (max-width: 1199.98px) {
    width: 46px;
    height: 46px;
  }
`

const BannerDetail: React.FC<BannerProps> = ({ fields }) => {
  return (
    <Section className="position-relative">
      <Inner className="position-relative h-100 mx-auto">
        <IconWrapper className="position-absolute">
          <Icon image={fields.icon} alt="" />
        </IconWrapper>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="ms-md-5 ps-md-5">
                <div className="row">
                  <Content
                    content={fields.description}
                    className="position-relative"
                  />
                  {fields.usps?.map((usp, index) => (
                    <div className="position-relative col-4" key={index}>
                      <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center pb-5 pb-xl-0">
                        <UspIcon
                          image={usp?.icon}
                          alt=""
                          className="rounded-circle p-2 p-xl-3 me-sm-4"
                        />
                        <span className="text-white text-center text-sm-start font-weight-bold">
                          {usp?.link?.title}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Inner>
    </Section>
  )
}

export default BannerDetail
